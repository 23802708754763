<template>
  <a-modal
    title="客户计费配置"
    :width="800"
    :visible="visible"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel">
    <a-form :form="form" :label-col="{ span: 9 }" :wrapper-col="{ span: 15 }" @submit="handleSubmit">
      <a-row>
        <a-col :span="24">
          <a-form-item label="客户" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
            <a-select v-decorator="['client', { rules: [{ required: true, message: '请选择客户' }]}]" disabled style="width: 100%">
              <a-select-option v-for="item in clientItems" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="入库配送单价(元)">
            <a-input v-decorator="['stock_in_transport_unit_price', { rules: [{ required: true, message: '请输入入库配送单价' }, { pattern: /^\d{0,14}(\.\d{0,20})?$/, message: '入库配送单价格式不正确' }]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="入库配送计费单位">
            <a-select v-decorator="['stock_in_transport_measure_unit', { rules: [{ required: false, message: '请选择单位' }]}]" style="width: 100%">
              <a-select-option v-for="item in unitItems" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="入库卸货单价(元)">
            <a-input v-decorator="['stock_in_handling_unit_price', { rules: [{ required: true, message: '请输入入库卸货单价' }, { pattern: /^\d{0,14}(\.\d{0,20})?$/, message: '入库卸货单价格式不正确' }]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="入库卸货计费单位">
            <a-select v-decorator="['stock_in_handling_measure_unit', { rules: [{ required: false, message: '请选择单位' }]}]" style="width: 100%">
              <a-select-option v-for="item in unitItems" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="仓储单价(元)">
            <a-input v-decorator="['warehouse_storage_unit_price', { rules: [{ required: true, message: '请输入仓储单价' }, { pattern: /^\d{0,14}(\.\d{0,20})?$/, message: '仓储单价格式不正确' }]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="仓储计费单位">
            <a-select v-decorator="['warehouse_storage_measure_unit', { rules: [{ required: false, message: '请选择单位' }]}]" style="width: 100%">
              <a-select-option v-for="item in unitItems" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="出库装车单价(元)">
            <a-input v-decorator="['stock_out_handling_unit_price', { rules: [{ required: true, message: '请输入出库装车单价' }, { pattern: /^\d{0,14}(\.\d{0,20})?$/, message: '出库装车单价格式不正确' }]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="出库装车计费单位">
            <a-select v-decorator="['stock_out_handling_measure_unit', { rules: [{ required: false, message: '请选择单位' }]}]" style="width: 100%">
              <a-select-option v-for="item in unitItems" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="出库配送单价(元)">
            <a-input v-decorator="['stock_out_transport_unit_price', { rules: [{ required: true, message: '请输入出库配送单价' }, { pattern: /^\d{0,14}(\.\d{0,20})?$/, message: '出库配送单价格式不正确' }]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="出库配送计费单位">
            <a-select v-decorator="['stock_out_transport_measure_unit', { rules: [{ required: false, message: '请选择单位' }]}]" style="width: 100%">
              <a-select-option v-for="item in unitItems" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>
<script>
import { chargeConfigsCreate, chargeConfigsEdit } from '@/api/charging'
import { clientOption } from '@/api/option';

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      model: {},
      form: this.$form.createForm(this),
      clientItems: [],
      unitItems: [
        { label: '件', value: 'quantity' },
        { label: '千克', value: 'weight' },
        { label: '立方米', value: 'cubic' },
        { label: '平方米', value: 'square' },
        { label: '托', value: 'pallet' }
      ]
    }
  },
  methods: {
    show (record) {
      this.visible = true
      this.model = record
      this.$nextTick(() => {
        this.form.setFieldsValue({
          client: record.client,
          // amount: record.amount,
          stock_in_transport_unit_price: record.stock_in_transport_unit_price,
          stock_in_handling_unit_price: record.stock_in_handling_unit_price,
          warehouse_storage_unit_price: record.warehouse_storage_unit_price,
          stock_out_handling_unit_price: record.stock_out_handling_unit_price,
          stock_out_transport_unit_price: record.stock_out_transport_unit_price,
          stock_in_transport_measure_unit: record.stock_in_transport_measure_unit,
          stock_in_handling_measure_unit: record.stock_in_handling_measure_unit,
          warehouse_storage_measure_unit: record.warehouse_storage_measure_unit,
          stock_out_handling_measure_unit: record.stock_out_handling_measure_unit,
          stock_out_transport_measure_unit: record.stock_out_transport_measure_unit
        })
      })
      
      clientOption({ page_size: 999999 }).then(data => {
        this.clientItems = data.results;
      })
    },
    handleOk () {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true
          let formdata = values
          formdata.id = this.model.id
          chargeConfigsEdit(formdata).then(data => {
            this.$message.success('修改成功')
            this.$emit('ok')
            this.handleCancel()
          }).finally(() => {
            this.confirmLoading = false
          })
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.model = {}
      this.visible = false
    }
  }
}
</script>